import React from 'react';

import { GraphicInlineComponent } from '@assured/shared-types/ClaimWorkflow';

import TenantLogo from '../../TenantLogo';
import { InlineComponentSharedProps, InlineFC } from '../types/inlineComponentTypes';

const Graphic: InlineFC<InlineComponentSharedProps<GraphicInlineComponent>> = ({
  value: graphicData,
}) => {
  return (
    <div>
      {graphicData.value.graphic.type === 'tenantLogo' ? (
        <TenantLogo
          tenant={graphicData.value.graphic.tenant}
          size="large"
          className={'block mx-auto mb-8'}
        />
      ) : null}
    </div>
  );
};

export default Graphic;
