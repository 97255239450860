import React, { useEffect } from 'react';

import { BuilderStepComponent } from '@assured/shared-types/ClaimWorkflow';

import { useIntermediateValues } from '../../hooks';
import Icon from '../Icon';
import Overdrive from '../Overdrive';
import Select from './Select';
import Title from './Title';
import {
  StepComponentControlsBackHookProps, StepComponentControlsTitleProps, StepComponentFC,
  StepComponentSharedProps
} from './types/stepComponentTypes';

const CHILD_COMPONENTS = {
  select: Select,
};

const makeApplicableSubcomponents = (
  step_component: BuilderStepComponent,
  obj: Record<string, any>,
) => {
  return step_component.subcomponents.filter(c => {
    if (!c.filter) {
      return true;
    }

    let filterObj: Record<string, any> = c.filter;
    return Object.keys(filterObj).every(k => obj[k] === filterObj[k]);
  });
};

const makeCompletedSubcomponents = (
  applicableSubcomponents: BuilderStepComponent['subcomponents'],
  obj: Record<string, any>,
) => {
  return applicableSubcomponents.filter(
    c => c.field && typeof obj[c.field] !== 'undefined',
  );
};

type BuilderProps = StepComponentSharedProps<
  BuilderStepComponent,
  object[] | null
> &
  StepComponentControlsBackHookProps &
  StepComponentControlsTitleProps &
  Record<string, any>;

const Builder: StepComponentFC<BuilderProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  titleClassName,
  registerBackHook,
  removeBackHook,
  ...rest
}) => {
  const { obj, setObj, submit, hasSubmitted } = useIntermediateValues({
    step_component,
    updateValue,
    initialObj: step_component.initial_value,
  });

  // Apply filters
  const applicableSubcomponents = makeApplicableSubcomponents(
    step_component,
    obj,
  );

  // Instantly submit if single-entry field
  const shouldAutoSubmit =
    applicableSubcomponents.length === 1 &&
    applicableSubcomponents[0].field &&
    typeof obj[applicableSubcomponents[0].field] !== 'undefined';
  useEffect(() => {
    if (!hasSubmitted && shouldAutoSubmit) {
      submit();
    }
  }, [shouldAutoSubmit, obj]);

  const completedSubcomponents = shouldAutoSubmit
    ? []
    : makeCompletedSubcomponents(applicableSubcomponents, obj);

  useEffect(() => {
    const backHook = () => {
      const applicableSubcomponents = makeApplicableSubcomponents(
        step_component,
        obj,
      );
      const completed = makeCompletedSubcomponents(
        applicableSubcomponents,
        obj,
      );

      if (completed.length) {
        const k = completed[completed.length - 1].field;
        k &&
          setObj({
            ...obj,
            [k]: undefined,
          });
        return true;
      }
      return false;
    };
    registerBackHook(backHook);
    return () => removeBackHook(backHook);
  }, [obj]);

  // Buttons at the top
  const builderButtons = completedSubcomponents.map((c, i) => {
    let inner;

    if (c.type === 'select') {
      if (c.mode === 'color') {
        inner = (
          <div
            className="rounded-full border-2 border-cool-gray-300 bg-white"
            style={{
              width: 30,
              height: 30,
              backgroundColor: c.field && obj[c.field],
            }}
          />
        );
      } else if (c.options) {
        const opt = c.options.find(o => c.field && o.value === obj[c.field]);
        if (opt && (opt.icon_text || opt.icon)) {
          inner = opt.icon_text || <Icon icon={opt.icon} small />;
        }
      }
    }

    if (!inner) {
      inner = c.field && obj[c.field];
    }

    const valueId = c.mode === 'dropdown' ? c.mode : c.field && obj[c.field];

    return (
      <Overdrive
        id={`${step_component.id}-${c.field}-${valueId}`}
        key={c.field}
        duration={400}
      >
        <button
          className={`select-btn p-2 px-4 text-cool-gray-600 text-${
            inner.length > 8 ? 'xl' : '2xl'
          }`}
          style={{ minHeight: 60, minWidth: 60 }}
          onClick={() => {
            let newObj = { ...obj };
            applicableSubcomponents
              .slice(i)
              .map(c => c.field)
              .forEach(f => {
                f && delete newObj[f];
              });
            setObj(newObj);
          }}
        >
          {inner}
        </button>
      </Overdrive>
    );
  });

  // Current step
  const currentComponentDef = shouldAutoSubmit
    ? applicableSubcomponents[0]
    : applicableSubcomponents.find(c => {
        return c.field && typeof obj[c.field] === 'undefined';
      });

  const Component = currentComponentDef
    ? CHILD_COMPONENTS[
        currentComponentDef.type as keyof typeof CHILD_COMPONENTS
      ]
    : null;

  let RenderedComponent =
    currentComponentDef && Component ? (
      <div
        key={currentComponentDef.field}
        className="ClaimWorkflowInnerBuilder"
      >
        <div className="text-md my-2 text-cool-gray-600">
          {currentComponentDef.title}
        </div>
        <Component
          step_component={currentComponentDef}
          primaryValue={
            currentComponentDef.field ? obj[currentComponentDef.field] : null
          }
          updateValue={(k, v) => k && setObj({ ...obj, [k]: v })}
          overdriveField={`${step_component.id}-${currentComponentDef.field}`}
          {...rest}
        />
      </div>
    ) : null;

  return (
    <div className={className}>
      <Title
        title={
          (builderButtons.length && step_component.intermediate_title) ||
          step_component.title
        }
        titleClassName={titleClassName}
      />
      <div className="mt-4">
        {builderButtons.length ? (
          <div className="flex justify-center items-center flex-wrap -mx-6">
            {builderButtons}
          </div>
        ) : null}
        {RenderedComponent ? (
          RenderedComponent
        ) : (
          <button className="mt-4 btn btn-blue inline-block" onClick={submit}>
            {step_component.builder_submit_label || 'Continue'}
          </button>
        )}
        {RenderedComponent && step_component.builder_skip_label ? (
          <button className="mt-6 btn btn-subtle inline-block" onClick={submit}>
            {step_component.builder_skip_label}
          </button>
        ) : null}
      </div>
    </div>
  );
};

Builder.stepConfig = {
  controlsTitle: true,
};

export default Builder;
