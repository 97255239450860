import classNames from 'classnames';
import React from 'react';
import reactStringReplace from 'react-string-replace';

import { InlineComponentType } from '@assured/shared-types/ClaimWorkflow';
import { CustomString } from '@assured/shared-types/ClaimWorkflow/additional';

import InjectInline from './InjectInline';

export const formatHighlightedTerms = (text: string) =>
  reactStringReplace(text, /\[\[(.*?)\]\]/g, (match, i) => (
    <span
      key={i}
      className="rounded border border-cool-gray-600 border-2 px-1 mx-1 shadow font-semibold whitespace-nowrap"
    >
      {match}
    </span>
  ));

type Injector = {
  type: InlineComponentType;
  value: string;
};

interface TitleProps {
  title?: CustomString | CustomString[];
  subtitle?: CustomString | CustomString[];
  titleClassName?: string;
  inline?: boolean;
  dark?: boolean;
  injectors?: Record<string, Injector>;
}

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  titleClassName,
  inline,
  dark,
  injectors,
}) => {
  const renderComponent = (
    def: CustomString | CustomString[],
    Component: React.ElementType,
    className: string,
    custom: Record<string, any>,
    injectors: Record<string, Injector> = {},
  ) =>
    def
      ? (Array.isArray(def) ? def : [def])
          .filter(t => !!t)
          .map(t => {
            let text: string, rest: { [key: string]: any };

            if (!t) {
              return;
            } else if (typeof t === 'string') {
              text = t;
              rest = {};
            } else {
              text = t.text;
              rest = { ...t, text: undefined };
            }

            let outputContent = formatHighlightedTerms(text);

            outputContent = reactStringReplace(
              outputContent,
              /(\[.*?\]\(.*?\))/g,
              (match, i) => {
                const res = /\[(.*?)\]\((.*?)\)/.exec(match);
                return (
                  res && (
                    <a
                      key={`link-${i}`}
                      className="underline hover:text-gray-800"
                      href={res[2]}
                      target="_blank"
                    >
                      {res[1]}
                    </a>
                  )
                );
              },
            );

            return (
              <Component
                key={text}
                className={classNames(
                  className,
                  titleClassName,
                  'mb-2',
                  Object.keys(rest).map(r => rest[r] && custom[r]),
                )}
              >
                <InjectInline nodes={outputContent} injectors={injectors} />
              </Component>
            );
          })
      : null;

  return (
    <React.Fragment>
      {title &&
        renderComponent(
          title,
          'h2',
          `text-lg ${dark ? 'text-white' : 'text-cool-gray-600'}`,
          {
            bold: 'font-medium',
            big: `text-5xl`,
            small: `text-base`,
            heading: 'text-3xl font-bold leading-8 mb-3',
          },
          injectors,
        )}
      {subtitle &&
        renderComponent(
          subtitle,
          'h3',
          `text-md mt-2 ${dark ? 'text-white' : 'text-cool-gray-600'}`,
          {
            bold: 'font-medium',
            big: `text-3xl -mt-1`,
            small: `text-sm`,
            monospace: 'font-mono',
          },
          injectors,
        )}
    </React.Fragment>
  );
};
export default Title;
