import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { BodilyInjuryStepComponent } from '@assured/shared-types/ClaimWorkflow';
import {
  BodilyInjuryPayload, BodilyInjuryPerspective, BodilyInjuryRegion
} from '@assured/shared-types/ClaimWorkflow/BodilyInjury';

import BodilyInjuryMap from '../../BodilyInjuryMap';
import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';
import BodilyInjuryDetailModal from './BodilyInjuryDetailModal';
import { BODILY_INJURY_REGIONS } from './consts';

interface BodilyInjuryProps
  extends StepComponentSharedProps<BodilyInjuryStepComponent, string | null> {}

const FlipIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className={className}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 7C6.48 7 2 9.24 2 12c0 2.24 2.94 4.13 7 4.77V20l4-4-4-4v2.73c-3.15-.56-5-1.9-5-2.73 0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5z" />
  </svg>
);

const BodilyInjury: StepComponentFC<BodilyInjuryProps> = ({
  step_component,
  updateValue,
  primaryValue,
  className,
}) => {
  let value: BodilyInjuryPayload = {
    regions: [],
  };

  const singleRegionPicker = step_component.single_region_picker;

  if (singleRegionPicker) {
    value = {
      regions: [
        {
          key: singleRegionPicker,
          options: Array.isArray(primaryValue)
            ? primaryValue.map(o => ({ key: o, data: {} }))
            : [],
        },
      ],
    };
  } else if (primaryValue && !Array.isArray(primaryValue)) {
    value = JSON.parse(primaryValue);
  }

  console.log('value=', value, 'primary=', primaryValue);

  const [hasInteracted, setHasInteracted] = useState(false);

  const [selectedPerspective, setSelectedPerspective] =
    useState<BodilyInjuryPerspective>('front');
  const [selectedRegion, setSelectedRegion] =
    useState<BodilyInjuryRegion | null>(
      step_component.single_region_picker || null,
    );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!hasInteracted && value.regions.some(r => r.key === 'back')) {
      setSelectedPerspective('back');
    }
  }, [value, hasInteracted]);

  const modal = (
    <BodilyInjuryDetailModal
      open={modalOpen}
      singleRegionPicker={singleRegionPicker}
      onClose={() => setModalOpen(false)}
      specification={BODILY_INJURY_REGIONS.find(r => r.key === selectedRegion)}
      isLowSeverity={!!step_component.low_severity}
      displayMode={step_component.display_mode}
      iconTheme={step_component.icon_theme}
      allowOtherEntry={step_component.allow_other_entry}
      value={value.regions.find(r => r.key === selectedRegion)}
      onChange={v => {
        setHasInteracted(true);

        if (singleRegionPicker) {
          if (v.options.length) {
            updateValue(step_component.field, v.options.map(o => o.key) as any);
          } else {
            updateValue(step_component.field, null);
          }
          return;
        }

        let newValue = { ...value };
        newValue.regions = newValue.regions.filter(
          r => r.key !== selectedRegion,
        );
        if (v.options.length) {
          newValue.regions.push(v);
        }
        updateValue(
          step_component.field,
          newValue.regions.length ? JSON.stringify(newValue) : null,
        );
      }}
    />
  );

  if (singleRegionPicker) {
    return <div className={classNames('mt-6', className)}>{modal}</div>;
  }

  return (
    <div className={classNames('mt-2 relative', className)}>
      <div className="mt-6 mb-2 flex justify-center">
        <button
          className="px-2 rounded-lg border border-cool-gray-300 flex items-center text-cool-gray-600 hover:bg-cool-gray-100 focus:outline-none hover:shadow-xs"
          onClick={() => {
            setHasInteracted(true);
            setSelectedPerspective(p => (p === 'back' ? 'front' : 'back'));
          }}
        >
          <FlipIcon className="w-6 h-6 mr-1" />
          <span className="font-medium text-sm">
            Flip to {selectedPerspective === 'front' ? 'back' : 'front'}
          </span>
        </button>
      </div>
      <BodilyInjuryMap
        selectedPerspective={selectedPerspective}
        selectedRegions={value.regions.map(r => r.key)}
        onRegionSelect={key => {
          setSelectedRegion(key);
          setModalOpen(true);
        }}
      />
      {modal}{' '}
    </div>
  );
};

BodilyInjury.stepConfig = {
  manualSubmit: true,
};

export default BodilyInjury;
