import React from 'react';
import ReactConfetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';

import { ConfettiWidget } from '@assured/shared-types/ClaimWorkflow';

import { WidgetComponentSharedProps, WidgetFC } from '../types/widgetComponentTypes';

const Confetti: WidgetFC<WidgetComponentSharedProps<ConfettiWidget>> = ({
  widget,
  className,
}) => {
  const { width, height } = useWindowSize();
  return (
    <ReactConfetti
      width={width}
      height={height}
      style={{ position: 'fixed' }}
      recycle={false}
      numberOfPieces={300}
    />
  );
};

export default Confetti;
