import React, { useEffect, useRef, useState } from 'react';

import { Coordinate } from '@assured/shared-types/ClaimWorkflow/additional';

import config from '../../../config';
import { default as CustomCarKnob } from './CustomCarKnob';
import LocationMap from './LocationMap';

interface LocationKnobProps {
  onValueChange: (value: number) => void;
  value: number;
  center?: Coordinate | null;
  interactive?: boolean;
  highFidelity?: boolean;
  vehicleColor?: string;
}
const LocationKnob: React.FC<LocationKnobProps> = ({
  value,
  onValueChange,
  center,
  interactive,
  highFidelity,
  vehicleColor,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);

  const marker = (
    <CustomCarKnob
      diameter={200}
      min={0}
      max={360}
      step={0.5}
      value={value}
      onValueChange={onValueChange}
      spaceMaxFromZero={false}
      interactive={interactive}
      color={vehicleColor}
    />
  );

  useEffect(() => {
    function computeWidth() {
      setWidth(containerRef.current?.clientWidth || 0);
    }
    window.addEventListener('resize', () => computeWidth());
    computeWidth();
  }, []);

  return (
    <div
      ref={r => {
        containerRef.current = r;
      }}
      data-not-copyable
      className="rounded overflow-hidden mt-6 mx-auto"
      style={{
        height: highFidelity ? 300 : 250,
        width: '100%',
        position: 'relative',
      }}
    >
      {highFidelity ? (
        <>
          <div className="w-full h-full flex items-center justify-center">
            <img
              className="absolute inset-0"
              src={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
                center?.longitude
              },${center?.latitude},${18},0,0/${width}x300?access_token=${
                config.mapboxAccessToken
              }`}
            />
            <div>{marker}</div>
          </div>
        </>
      ) : (
        <LocationMap
          center={center}
          zoom={18}
          loaded={true}
          interactive={false}
          customMarker={marker}
          mapTypeId={'hybrid'}
        />
      )}
    </div>
  );
};

export default LocationKnob;
