import './String.css';
import 'react-phone-number-input/style.css';

import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Props as PhoneInputProps } from 'react-phone-number-input';
//@ts-ignore
import PhoneNumberInput from 'react-phone-number-input/input';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { StringStepComponent } from '@assured/shared-types/ClaimWorkflow';

import Label from '../Label';
import {
  StepComponentControlsErrorProps, StepComponentFC, StepComponentSharedProps,
  StepComponentShowsPrefillProps
} from '../types/stepComponentTypes';
import IncrementableSmallNumber from './IncrementableSmallNumber';
import UnitInput from './UnitInput';

type StringProps = StepComponentSharedProps<
  StringStepComponent,
  string | number | null
> &
  StepComponentControlsErrorProps &
  StepComponentShowsPrefillProps;

const DEFAULT_COUNTRY = 'US';

//FIXME(06-15-2020) a lot of cleaning up to do here
const String: StepComponentFC<StringProps> = ({
  step_component,
  primaryValue,
  updateValue,
  attemptSubmit,
  error,
  showErrorMessages,
  className,
  showsPrefill,
}) => {
  const correctCapitalization = (v: string) => {
    if (
      ['license_plate', 'insurance_number'].includes(
        step_component.mode || '',
      ) &&
      v
    ) {
      v = v.toUpperCase();
    }

    if (v?.length && step_component.mode !== 'email') {
      v = v[0].toUpperCase() + v.substring(1);
    }
    return v;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let v = e ? correctCapitalization(e.target.value) : null;
    updateValue(step_component.field, v);
  };

  useEffect(() => {
    if (
      showsPrefill &&
      !step_component.existing_value &&
      step_component.mode === 'phone_number'
    ) {
      console.log('SETTING EXISTING VALUE');
      step_component.existing_value = '+14154567890';
    }
  }, []);

  let Component: React.ElementType = 'input',
    additionalProps:
      | {}
      | PhoneInputProps<HTMLInputElement>
      | (MaskedInputProps & {
          onRawChange?: (v: number) => void;
          minimum?: number;
        })
      | {
          onRawChange?: (v: number) => void;
          subdivisions: any;
        }
      | { rows: number } = {},
    additionalContent,
    narrow = !!step_component.narrow;

  if (step_component.mode === 'phone_number') {
    Component = PhoneNumberInput;
    additionalProps = {
      country: DEFAULT_COUNTRY,
      // defaultCountry: DEFAULT_COUNTRY,
      value: `${primaryValue}`,
      type: 'tel',
      displayInitialValueAsLocalNumber: true,
      onChange: (v: string) => {
        updateValue(step_component.field, correctCapitalization(v));
      },
      // countrySelectProps: { tabIndex: -1, unicodeFlags: true },
    };
  } else if (step_component.mode === 'currency') {
    Component = MaskedInput;
    let v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      mask: createNumberMask({}),
      type: 'tel',
      value: v / 100 || undefined,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e ? +e.target.value.replace(/[^0-9.]/g, '') : null;
        updateValue(step_component.field, val ? Math.round(val * 100) : null);
      },
      placeholder: '$0',
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (step_component.mode === 'paragraph') {
    Component = 'textarea';
    additionalProps = { rows: 4 };
    if (step_component.paragraph_mode === 'workers_comp_description') {
      const nChars = typeof primaryValue === 'string' ? primaryValue.length : 0;
      const GOAL_CHARS = 280;
      const message =
        nChars < 10
          ? 'Start typing...'
          : nChars < 100
          ? 'Keep going...'
          : nChars < 200
          ? 'Add some more detail...'
          : nChars < 280
          ? 'Perfect!'
          : 'Oops! Too much.';

      const r = 10;
      const circleLength = 2 * Math.PI * r;
      const colored = (circleLength * nChars) / 280;
      const gray = circleLength - colored > 0 ? circleLength - colored : 0;

      additionalContent = (
        <div className="flex items-center justify-end">
          <div className="font-medium text-sm text-cool-gray-600">
            {message}
          </div>
          <svg className="ml-2 w-7 h-10 inline-block">
            <circle
              id="gray"
              cx="50%"
              cy="50%"
              r={r}
              style={{ fill: 'none', strokeWidth: 6, stroke: 'lightgray' }}
            ></circle>
            <circle
              id="colored"
              cx="50%"
              cy="50%"
              r={r}
              style={{
                fill: 'none',
                strokeWidth: 6,
                stroke:
                  GOAL_CHARS - nChars <= 0
                    ? 'red'
                    : GOAL_CHARS - nChars <= 20
                    ? 'orange'
                    : '#3f83f8',
                strokeDasharray: `${colored} ${gray}`,
              }}
            />
          </svg>
        </div>
      );
    }
  } else if (step_component.mode === 'small_number') {
    // To Consider: Should we refactor number/currency/etc to a separate primitive input type
    // instead of overloading String?
    Component = IncrementableSmallNumber;
    let v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      mask: createNumberMask({ prefix: '' }),
      type: 'tel',
      value: v || undefined,
      onRawChange: (v: number) => updateValue(step_component.field, v),
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e ? +e.target.value.replace(/[^0-9]/g, '') : null;
        if (typeof val === 'number' && step_component.minimum) {
          val = Math.max(step_component.minimum, val);
        }
        updateValue(step_component.field, val ? Math.min(val, 99) : null);
      },
      placeholder: '0',
      style: { textAlign: 'center', maxWidth: 60 },
      minimum: step_component.minimum,
    };
    narrow = true;
  } else if (step_component.mode === 'feet_inches') {
    Component = UnitInput;
    let v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      value: v || undefined,
      onRawChange: (v: number) => updateValue(step_component.field, v),
      subdivisions: [
        {
          label: 'ft',
          base_value: 12,
        },
        {
          label: 'in',
          base_value: 1,
        },
      ],
      style: { textAlign: 'center' },
    };
    narrow = true;
  } else if (
    step_component.mode === 'number' ||
    step_component.mode === 'percentage'
  ) {
    Component = MaskedInput;
    let v = primaryValue ? +primaryValue : 0;

    additionalProps = {
      mask: createNumberMask({
        prefix: '',
        suffix: step_component.mode === 'percentage' ? '%' : undefined,
      }),
      type: 'tel',
      value: v || undefined,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e ? +e.target.value.replace(/[^0-9]/g, '') : null;
        updateValue(step_component.field, val ? Math.round(val) : null);
      },
      placeholder: step_component.mode === 'percentage' ? '0%' : '0',
      style: { textAlign: 'center' },
    };
    narrow = true;
  }

  return (
    <div
      className={classNames(
        className,
        'mt-4',
        narrow ? 'text-center' : 'text-left',
        step_component.textbox_class_name_override,
      )}
    >
      {step_component.label ? <Label step_component={step_component} /> : null}
      <Component
        id={step_component.step_component_id}
        type={step_component.mode === 'email' ? 'email' : 'text'}
        placeholder={step_component.placeholder}
        value={primaryValue || ''}
        onChange={onChange}
        className={classNames('textbox', error && 'Shake border-red-500')}
        style={narrow ? { maxWidth: 250 } : {}}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (
            attemptSubmit &&
            step_component.mode !== 'paragraph' &&
            e.key === 'Enter'
          ) {
            attemptSubmit();
          }
        }}
        {...additionalProps}
      />
      {additionalContent}
      {error && showErrorMessages ? (
        <div className="error-message">{error}</div>
      ) : null}
    </div>
  );
};

String.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};

export default String;
