import classNames from 'classnames';
import React, { useState } from 'react';

import { SelectMultiStepComponent } from '@assured/shared-types/ClaimWorkflow';

import Icon from '../Icon';
import Toggle from '../Toggle';
import OtherModal from './OtherModal';
import { formatHighlightedTerms } from './Title';
import {
  StepComponentFC, StepComponentOtherValue, StepComponentSharedProps
} from './types/stepComponentTypes';

type SelectMultiProps = StepComponentSharedProps<
  SelectMultiStepComponent,
  (string | boolean)[] | (boolean | string)
> &
  StepComponentOtherValue<string>;

const SelectMulti: StepComponentFC<SelectMultiProps> = ({
  step_component,
  primaryValue,
  otherValue,
  updateValue,
  className,
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);

  return (
    <div
      className={classNames(
        'mt-4 text-left',
        className,
        step_component.dense && 'flex flex-wrap',
        step_component.big_icon_buttons && 'mx-auto',
      )}
      style={step_component.big_icon_buttons ? { maxWidth: 200 } : {}}
    >
      <OtherModal
        onUpdate={v =>
          //FIXME(06-23-2020) Kinda stumped since this is the only place that doesn't fit the pattern
          step_component.other_field &&
          updateValue(step_component.other_field, v as any)
        }
        onSave={() => setShowOtherInput(false)}
        showInput={showOtherInput}
        prompt={step_component.other_prompt || 'Something else'}
        value={otherValue}
      />
      {step_component.options?.map(({ value: v, label, icon, icon_text }) => {
        const propValue =
          typeof primaryValue === 'undefined' || Array.isArray(primaryValue)
            ? primaryValue
            : [primaryValue];
        const selected = (propValue || []).indexOf(v || '') !== -1;

        const isOther = v === step_component.other_option;
        const displayLabel =
          (isOther &&
            (otherValue || (step_component.other_existing_value as string))) ||
          label;

        const onCheckboxChange = (
          e: React.ChangeEvent<HTMLInputElement> | boolean,
        ) => {
          const set = new Set(step_component.single_toggle ? [] : propValue);
          if (
            v &&
            ((typeof e === 'boolean' && e) ||
              (typeof e !== 'boolean' && e.target.checked))
          ) {
            set.add(v);
            if (isOther) {
              setShowOtherInput(true);
            }
          } else if (v) {
            if (
              step_component.single_toggle &&
              typeof step_component.existing_value !== 'undefined' &&
              step_component.existing_value !== null &&
              !Array.isArray(step_component.existing_value)
            ) {
              set.add(step_component.existing_value);
            }
            set.delete(v);
          }
          if (set.size === 0 && step_component.single_toggle) {
            set.add(false);
          }

          updateValue(
            step_component.field,
            step_component.single_toggle ? [...set][0] : [...set],
          );
        };

        if (step_component.mode === 'yes_no') {
          return (
            <div
              key={v?.toString()}
              className="flex items-center mb-4 py-4 px-6 rounded-md border border-cool-gray-200 bg-cool-gray-50 font-medium text-cool-gray-700"
            >
              <div className="flex-1">
                {formatHighlightedTerms(displayLabel)}
              </div>
              <div>
                <Toggle
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  value={selected}
                  onChange={onCheckboxChange}
                />
              </div>
            </div>
          );
        }

        if (step_component.big_icon_buttons) {
          return (
            <label
              key={v?.toString()}
              className={classNames(
                'flex flex-col relative border-solid border-2 rounded-lg p-2 px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out my-8 text-center',
                selected && 'bg-blue-100 border-blue-300',
              )}
            >
              <input
                type="checkbox"
                className={classNames(
                  'absolute form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-8 w-8 text-blue-600 transition duration-150 ease-in-out transform',
                  selected && 'h-10 w-10',
                )}
                style={{
                  top: '-1rem',
                  right: '-1rem',
                  ...(selected && { transform: `rotate(7.5deg)` }),
                }}
                checked={selected}
                onChange={onCheckboxChange}
              />
              <Icon icon={icon} text={icon_text} />
              <div
                className={classNames(
                  'text-cool-gray-600 leading-none text-lg mb-4',
                )}
              >
                {formatHighlightedTerms(displayLabel)}
              </div>
            </label>
          );
        }

        return (
          <label
            key={v?.toString()}
            className={classNames(
              'flex border-solid border-2 rounded-lg p-2 px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out',
              selected && 'bg-blue-100 border-blue-300',
              step_component.dense ? 'm-2 flex-1' : 'my-4',
            )}
          >
            <input
              type="checkbox"
              className="form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              checked={selected}
              onChange={onCheckboxChange}
            />
            <span className="flex-1 text-cool-gray-700 ml-4">
              {formatHighlightedTerms(displayLabel)}
            </span>
          </label>
        );
      })}
    </div>
  );
};

SelectMulti.stepConfig = {
  manualSubmit: true,
};

export default SelectMulti;
