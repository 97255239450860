import { useEffect, useState } from 'react';

import { ClaimWorkflowStepComponent } from '@assured/shared-types/ClaimWorkflow';

/**
 * Store intermediate values.
 */
export function useIntermediateValues({
  step_component,
  updateValue,
  postProcess = obj => obj,
  initialObj = {},
}: {
  step_component: ClaimWorkflowStepComponent;
  updateValue: (field: string | undefined, value: any) => void;
  postProcess?: (obj: Record<string, any>) => Record<string, any>;
  initialObj?: Record<string, any>;
}) {
  const [obj, setObj] = useState({ ...initialObj });
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // Reset values when the step changes.
  useEffect(() => {
    setObj({ ...initialObj });
    setHasSubmitted(false);
  }, [step_component]);

  const submit = () => {
    setHasSubmitted(true);
    updateValue(step_component.field, postProcess ? postProcess(obj) : obj);
  };

  return { obj, setObj, hasSubmitted, submit };
}
