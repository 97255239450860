import classNames from 'classnames';
import React, { useState } from 'react';

import { LocationDirectionStepComponent } from '@assured/shared-types/ClaimWorkflow';

import LocationKnob from './Location/LocationKnob';
import { StepComponentFC, StepComponentSharedProps } from './types/stepComponentTypes';

type LocationDirectionProps = StepComponentSharedProps<
  LocationDirectionStepComponent,
  number | null
>;

const LocationDirection: StepComponentFC<LocationDirectionProps> = ({
  step_component,
  updateValue,
  className,
}) => {
  const [value, setValue] = useState(step_component.existing_value || 0);
  return (
    <div className={className}>
      <LocationKnob
        center={step_component.location_center}
        highFidelity={step_component.high_fidelity}
        vehicleColor={step_component.vehicle_color}
        value={value}
        onValueChange={setValue}
        interactive={true}
      />
      <div className="mt-6 md:flex justify-center">
        <button
          onClick={() => updateValue(step_component.field, value)}
          className={classNames('btn btn-blue')}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LocationDirection;
