import 'cross-fetch/polyfill';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import config from '../config';
import { getTokenSilently } from './auth';

const httpLink = new HttpLink({ uri: config.endpoint, credentials: 'include' });

const authLink = setContext(async (request, { headers }) => {
  let updatedHeaders = { ...headers };

  if (config.multiTenantSelectedTenant) {
    updatedHeaders['x-assured-tenant'] = config.multiTenantSelectedTenant;
  }

  // Don't use token for specific unauthenticated operations. Prevents issues from
  // expired tokens, since even if your old token is expired, it shouldn't matter because
  // we are looking up/creating a whole new one.
  const NO_AUTH_OPERATIONS = [
    'GetTenantConfig',
    'LookupDemo',
    'ActivateDemo',
    'AuthenticateUser',
    'LookupAuthenticatedDeepLink',
    'StartNewLinkInvestigationResponse',
  ];
  if (
    request.operationName &&
    NO_AUTH_OPERATIONS.indexOf(request.operationName) !== -1
  ) {
    return { headers: updatedHeaders };
  }

  let token;
  try {
    token = await getTokenSilently();
  } catch (e: any) {
    // fall-through if not logged in
  }

  if (token) {
    updatedHeaders = {
      ...updatedHeaders,
      authorization: `Bearer ${token}`,
    };
  }

  return { headers: updatedHeaders };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});
