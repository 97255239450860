import classNames from 'classnames';
import React from 'react';

import { MapWidget } from '@assured/shared-types/ClaimWorkflow';

import LocationMap from '../Location/LocationMap';
import { WidgetComponentSharedProps, WidgetFC } from '../types/widgetComponentTypes';

const Map: WidgetFC<WidgetComponentSharedProps<MapWidget>> = ({
  widget,
  className,
}) => {
  return (
    <div
      className={classNames('rounded overflow-hidden my-6 mx-auto', className)}
      style={{
        height: 250,
        width: '100%',
        position: 'relative',
      }}
    >
      <LocationMap
        center={{ latitude: widget.latitude, longitude: widget.longitude }}
        zoom={18}
        loaded={true}
        interactive={false}
        mapTypeId={'hybrid'}
      />
    </div>
  );
};
export default Map;
