import classNames from 'classnames';
import React, { useRef, useState } from 'react';

import { Coordinate } from '@assured/shared-types/ClaimWorkflow/additional';
import { Autocomplete } from '@react-google-maps/api';

import { useLoadMaps } from '../../../hooks';
import Toggle from '../../Toggle';
import Title from '../Title';

interface LocationSubmitData extends Partial<Coordinate> {
  addressText: string;
  apartmentNumber: string | null;
}

interface LocationManualInputProps {
  noApartmentEntry?: boolean;
  noGpsEntry?: boolean;
  skipLabel?: string;
  onSkip?: () => void;
  autoSubmitInline?: boolean;
  initalAddressText?: string;
  onDevice: () => void;
  onSubmit: (location: LocationSubmitData) => void;
}

const LocationManualInput: React.FC<LocationManualInputProps> = ({
  noApartmentEntry,
  noGpsEntry,
  autoSubmitInline,
  initalAddressText,
  skipLabel,
  onSkip,
  onDevice,
  onSubmit,
}) => {
  const { isLoaded } = useLoadMaps();
  const [place, setPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [address, setAddress] = useState<string>('');
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  const [isApartment, setIsApartment] = useState<boolean>(false);
  const [apartmentNumber, setApartmentNumber] = useState('');

  const hasApartmentField = !noApartmentEntry;

  const canConfirm =
    place &&
    (!hasApartmentField ||
      (isApartment !== null && (isApartment === false || apartmentNumber)));

  const onConfirm = () => {
    if (canConfirm) {
      onSubmit({
        ...(place?.name && place.types?.includes('establishment')
          ? {
              businessName: place.name,
              businessGooglePlaceId: place.place_id,
            }
          : {
              businessName: null,
              businessGooglePlaceId: null,
            }),
        addressText: `${address}${
          isApartment ? ` (Apartment #${apartmentNumber})` : ''
        }`,
        apartmentNumber: apartmentNumber || null,
        latitude:
          place && place.geometry ? place.geometry.location.lat() : undefined,
        longitude:
          place && place.geometry ? place.geometry.location.lng() : undefined,
      });
    }
  };

  const AddressInput = (
    <input
      type="text"
      className="textbox"
      placeholder={
        initalAddressText ||
        (autoSubmitInline ? '' : 'Start typing an address...')
      }
    />
  );

  return (
    <div className="mt-4">
      {isLoaded ? (
        <Autocomplete
          onLoad={ref => {
            autocomplete.current = ref;
          }}
          onPlaceChanged={() => {
            if (autocomplete.current) {
              const place = autocomplete.current.getPlace();
              if (place.formatted_address) {
                setAddress(place.formatted_address);
              }
              setPlace(place.geometry ? autocomplete.current.getPlace() : null);
              if (autoSubmitInline) {
                setTimeout(() => onConfirm(), 100);
              }
            }
          }}
        >
          {AddressInput}
        </Autocomplete>
      ) : (
        <div>{AddressInput}</div>
      )}
      {hasApartmentField ? (
        <div className="mt-5 mb-5">
          <Title subtitle={'Is this an apartment?'} />
          <Toggle
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            value={isApartment}
            onChange={v => typeof v === 'boolean' && setIsApartment(v)}
          />
          {isApartment ? (
            <div style={{ maxWidth: 200 }} className="mx-auto mt-3 text-center">
              <input
                type="text"
                className="textbox ClaimWorkflowTitle"
                placeholder="Apartment number"
                value={apartmentNumber}
                onChange={e => setApartmentNumber(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {!autoSubmitInline ? (
        <div className="mt-4 md:flex md:flex-row md:flex-row-reverse justify-center">
          <button
            className={classNames(
              `btn btn-blue`,
              !canConfirm && 'btn-disabled',
            )}
            onClick={onConfirm}
            disabled={!canConfirm}
          >
            Confirm location
          </button>
          {!noGpsEntry ? (
            <button className="btn btn-subtle" onClick={onDevice}>
              Switch to GPS
            </button>
          ) : null}
          {skipLabel && noGpsEntry ? (
            <button className="btn btn-subtle" onClick={onSkip}>
              {skipLabel}
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LocationManualInput;
