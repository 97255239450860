import React, { useState } from 'react';

import { PolicyInlineComponent } from '@assured/shared-types/ClaimWorkflow';

import CoverageIcon from '../../elements/CoverageIcon';
import Modal from '../../Modal';
import { InlineComponentSharedProps, InlineFC } from '../types/inlineComponentTypes';

const Policy: InlineFC<InlineComponentSharedProps<PolicyInlineComponent>> = ({
  value: policyData,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <div className="rounded-md bg-blue-100 py-4 px-4 items-center flex text-blue-600 my-4">
        <CoverageIcon
          icon={policyData.value.policyInfo.icon ?? 'roadside_assistance'}
          className="w-16 -ml-3 -my-4"
        />
        <div className="flex-1 text-xs font-medium leading-4">
          {policyData.value.policyInfo.inlineText ? (
            policyData.value.policyInfo.inlineText
          ) : (
            <>
              Good news—your tow is covered by{' '}
              {policyData.value.policyInfo.insurerName}!
            </>
          )}{' '}
          <span
            className="inline-flex items-center border-blue-600 border-b-2 font-semibold cursor-pointer"
            onClick={() => setOpen(true)}
          >
            Learn more
            <svg
              className="w-5 h-5 ml-1 inline-block"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </span>
        </div>
      </div>
      <Modal
        title={policyData.value.policyInfo.modalTitle ?? 'Tow Coverage'}
        icon="check"
        body={
          <React.Fragment>
            <div>
              {policyData.value.policyInfo.modalText ??
                'Sorry, policy detailed info not found.'}
            </div>
          </React.Fragment>
        }
        open={open}
        customDismiss={() => setOpen(false)}
        actions={[
          {
            title: 'Coverage details',
            primary: false,
            className: '',
            onClick: (history: any) =>
              history.push('/policy/coverages?inline=true'),
          },
          {
            title: 'Got it',
            primary: true,
            className: '',
            onClick: () => setOpen(false),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default Policy;
