import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useDebounce from 'react-use/lib/useDebounce';
import Modal from '~/src/components/Modal';

import { ClinicalTableSearchStepComponent } from '@assured/shared-types/ClaimWorkflow';
import { PencilIcon, XIcon } from '@heroicons/react/solid';

import { StepComponentFC, StepComponentSharedProps } from '../types/stepComponentTypes';

type Awaited<T> = T extends Promise<infer U> ? U : T;

interface ClinicalTableSearchProps
  extends StepComponentSharedProps<
    ClinicalTableSearchStepComponent,
    any[] | any
  > {
  forceSubmit: () => void;
}

const QUERIES = {
  npi_individual: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  npi_organization: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  npi_hospital: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: 'provider_type:(*hospital* OR *clinic* OR *health*)',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  rxterm: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search`,
    q: '',
    fields: [
      'SXDG_RXCUI',
      'DISPLAY_NAME',
      'STRENGTHS_AND_FORMS',
      'RXCUIS',
      'DISPLAY_NAME_SYNONYM',
    ],
  },
} as const;

const prettyProviderType = (providerType?: string) => {
  if (providerType) {
    return providerType
      .replace(/Non-/g, 'Non\u0000')
      .replace(/-(.*)($|-| )/g, ' ($1)$2')
      .replace(/\u0000/g, '-');
  }
};

const randomManualItemId = () => {
  return `MANUALLY_ENTERED_${Math.floor(Math.random() * 1000000)}`;
};

const query = async (mode: keyof typeof QUERIES, term: string) => {
  if (!term) {
    return { term, results: [] };
  }

  const spec = QUERIES[mode];
  const params = new URLSearchParams({
    terms: term,
    q: spec.q,
    df: spec.fields.join(','),
    maxList: '8',
  });
  const res = await fetch(`${spec.endpoint}?${params}`).then(r => r.json());
  const [total_matched, codes, _, fields, __]: [
    number,
    string[],
    undefined,
    string[],
    undefined,
  ] = res;

  let results = codes.map((code, resultIndex) => {
    return {
      _id: code,
      ...(spec.fields as unknown as string[]).reduce(
        (obj, k, fieldIndex) => ({
          ...obj,
          [k]: fields[resultIndex][fieldIndex],
        }),
        {},
      ),
    } as Record<'_id' | typeof spec.fields[number], any>;
  });

  // Remove "duplicate" looking results
  const N_CHARS_NAME_MATCH = 5;
  results = results.filter((r, i) => {
    const firstOccurrence = results.findIndex(candidate => {
      const stateMatch =
        'addr_practice.state' in r &&
        candidate['addr_practice.state'] === r['addr_practice.state'];
      const zipMatch =
        'addr_practice.zip' in r &&
        candidate['addr_practice.zip'] === r['addr_practice.zip'];
      const nameMatch =
        'name.full' in r &&
        candidate['name.full']
          ?.toLowerCase()
          ?.substring(0, N_CHARS_NAME_MATCH) ===
          r['name.full'].toLowerCase()?.substring(0, N_CHARS_NAME_MATCH);
      return (mode === 'npi_organization' ? stateMatch : zipMatch) && nameMatch;
    });

    if (firstOccurrence !== -1 && firstOccurrence !== i) {
      return false;
    }

    return true;
  });

  // Max 5 best results for UX to prevent long scroll height
  results = results.slice(0, 5);

  return {
    term,
    results,
  };
};

const ClinicalTableSearch: StepComponentFC<ClinicalTableSearchProps> = ({
  step_component,
  updateValue,
  primaryValue,
  className,
  forceSubmit,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('');
  const [, cancel] = useDebounce(
    () => setDebouncedSearchTerm(searchTerm),
    100,
    [searchTerm],
  );

  type QueryRes = Awaited<ReturnType<typeof query>>;
  type QueryResultItem = Partial<QueryRes['results'][number]>;
  const [searchRes, setSearchRes] = useState<QueryRes | null>(null);

  // Since the results of the query() call can come back in a nondeterministic order,
  // only apply a result if a later result has not yet been returned. This prevents
  // race conditions where stale data overwrites the res *after* newer data has already
  // been loaded successfully.
  const queryOrderCounter = useRef({ applied: -1, dispatched: -1 });
  useEffect(() => {
    const queryIdx = ++queryOrderCounter.current.dispatched;
    query(step_component.mode, debouncedSearchTerm).then(r => {
      if (queryIdx > queryOrderCounter.current.applied) {
        queryOrderCounter.current.applied = queryIdx;
        setSearchRes(r);
      }
    });
  }, [debouncedSearchTerm]);

  const [selectedResults, setSelectedResults] = useState<QueryResultItem[]>(
    (step_component.list
      ? step_component.existing_results
      : 'existing_result' in step_component && step_component.existing_result
      ? [step_component.existing_result]
      : []
    )?.map(p =>
      step_component.mode === 'rxterm'
        ? p
        : {
            id: p.id,
            ...p.npiData,
          },
    ) || [],
  );

  const selectItem = (item: QueryResultItem) => {
    if (!selectedResults.find(rItem => rItem._id === item._id)) {
      setSelectedResults(results => [...results, item]);
    }
    setSearchRes(null);
    setSearchTerm('');
    setDebouncedSearchTerm('');
  };

  const deselectItem = (item: QueryResultItem) => {
    setSelectedResults(results => results.filter(i => i !== item));
  };

  const [isManuallyEnteringItem, setIsManuallyEnteringItem] = useState(false);
  const [manualEntryItem, setManualEntryItem] = useState<QueryResultItem>({
    _id: randomManualItemId(),
    NPI: 'MANUALLY_ENTERED',
  });
  const manualEntryReady =
    !!manualEntryItem['name.full'] &&
    (step_component.mode === 'rxterm' ||
      !!manualEntryItem['addr_practice.full']);
  const manualEntryLabel =
    step_component.mode === 'npi_hospital'
      ? 'hospital'
      : step_component.mode === 'npi_individual'
      ? 'doctor'
      : step_component.mode === 'npi_organization'
      ? 'organization'
      : step_component.mode === 'rxterm'
      ? 'prescription'
      : 'entry';

  const manualEntryButton = searchTerm ? (
    <>
      <div
        key={searchTerm}
        className="mt-4 p-4 cursor-pointer text-left rounded-md border border-cool-gray-300 hover:bg-cool-gray-50"
        onClick={() => {
          setManualEntryItem(i => ({
            ...i,
            'name.full': searchTerm,
          }));
          setIsManuallyEnteringItem(true);
        }}
      >
        <div className="font-medium text-cool-gray-800">{searchTerm}</div>
        <div className="text-sm font-medium text-cool-gray-500 flex items-center">
          <PencilIcon className="w-4 h-4 mr-1" />
          <span>Manually entered</span>
        </div>
      </div>
      {/* <button
        className="mt-4 rounded-md bg-blue-500 text-white hover:bg-blue-600 px-3 py-2 text-sm focus:outline-none focus:shadow-outline-blue inline-flex items-center leading-none"
        onClick={() => {
          setManualEntryItem(i => ({
            ...i,
            'name.full': searchTerm,
          }));
          setIsManuallyEnteringItem(true);
        }}
      >
        <PencilIcon className="w-4 h-4 mr-1" />
        <span>Enter details manually</span>
      </button> */}
    </>
  ) : null;

  return (
    <div className={classNames('mt-4 relative', className)}>
      <Modal
        open={isManuallyEnteringItem}
        title={`Add ${manualEntryLabel}`}
        body={
          <div className="mt-3 mb-6 text-left">
            <div>
              <label
                className="font-medium text-cool-gray-600 mb-1 block"
                htmlFor="name.full"
              >
                Full name
              </label>
              <input
                className="textbox text-base text-cool-gray-900"
                value={manualEntryItem['name.full'] || ''}
                onChange={e => {
                  const name = e.target.value;
                  setManualEntryItem(i => ({ ...i, ['name.full']: name }));
                }}
              />
            </div>
            {step_component.mode !== 'rxterm' ? (
              <div className="mt-4">
                <label
                  className="font-medium text-cool-gray-600 mb-1 block"
                  htmlFor="name.full"
                >
                  City and state
                </label>
                <input
                  className="textbox text-base text-cool-gray-900"
                  value={manualEntryItem['addr_practice.full'] || ''}
                  placeholder="Anytown, CA"
                  onChange={e => {
                    const name = e.target.value;
                    setManualEntryItem(i => ({
                      ...i,
                      ['addr_practice.full']: name,
                    }));
                  }}
                />
              </div>
            ) : null}
          </div>
        }
        actions={[
          {
            title: `Add ${manualEntryLabel}`,
            primary: true,
            onClick: () => {
              if (manualEntryReady) {
                selectItem(manualEntryItem);
                setManualEntryItem(i => ({ ...i, _id: randomManualItemId() }));
                setIsManuallyEnteringItem(false);
              }
            },
            className: manualEntryReady ? '' : 'btn-disabled',
          },
          {
            title: 'Never mind',
            className: 'sm:order-first',
            onClick: () => {
              setIsManuallyEnteringItem(false);
            },
          },
        ]}
      />
      {selectedResults ? (
        <div className="">
          {selectedResults.map(item => {
            return (
              <div
                key={item._id}
                className="bg-cool-gray-50 py-2 px-4 rounded-md border border-cool-gray-300 mb-4 text-left flex items-center text-cool-gray-800"
              >
                <span className="flex-1 font-medium">
                  {item['name.full'] || item['DISPLAY_NAME']}
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => deselectItem(item)}
                >
                  <XIcon className="w-4 h-4 text-cool-gray-400 hover:text-red-500" />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      {step_component.list || selectedResults.length === 0 ? (
        <>
          <input
            type="string"
            className="textbox"
            placeholder={
              selectedResults.length
                ? `Start typing ${
                    step_component.mode === 'npi_individual'
                      ? 'a last name '
                      : ''
                  }to add another...`
                : `Start typing ${
                    step_component.mode === 'npi_individual'
                      ? 'a last name '
                      : ''
                  }to search...`
            }
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <div className="">
            {searchTerm &&
            searchTerm === searchRes?.term &&
            searchRes?.results?.length === 0 ? (
              <div className="my-4 text-cool-gray-400">{manualEntryButton}</div>
            ) : (
              <>
                {searchRes?.results.map(r => {
                  return (
                    <div
                      key={r._id}
                      className="mt-4 p-4 cursor-pointer text-left rounded-md border border-cool-gray-300 hover:bg-cool-gray-50"
                      onClick={() => selectItem(r)}
                    >
                      {step_component.mode === 'rxterm' ? (
                        <>
                          <div className="font-medium text-cool-gray-800">
                            {r['DISPLAY_NAME']}
                          </div>
                          <div
                            className="text-sm font-medium text-cool-gray-600"
                            style={{
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: '3',
                            }}
                          >
                            {r.STRENGTHS_AND_FORMS?.split(',')
                              .map((x: string) => x.trim())
                              .join(', ')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="font-medium text-cool-gray-800">
                            {r['name.full']}
                          </div>
                          <div className="text-sm font-medium text-cool-gray-500">
                            {prettyProviderType(r.provider_type)}{' '}
                            {step_component.mode === 'npi_organization' ||
                            step_component.mode === 'npi_hospital' ? (
                              <> &middot; {r['addr_practice.state']}</>
                            ) : step_component.mode === 'npi_individual' ? (
                              <>
                                &middot; {r['addr_practice.city']},&nbsp;
                                {r['addr_practice.state']}
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
                {searchRes?.results?.length ? (
                  <div className="mt-4">{manualEntryButton}</div>
                ) : null}
              </>
            )}
          </div>
        </>
      ) : null}
      <div className="mt-4 flex justify-center flex-wrap sm:flex-nowrap">
        <button
          className={classNames(
            'btn btn-blue sm:order-last',
            !selectedResults.length && 'btn-disabled',
          )}
          onClick={() => {
            if (selectedResults.length) {
              if (step_component.mode === 'rxterm') {
                const value = selectedResults;
                updateValue(
                  step_component.field,
                  step_component.list
                    ? value.map(v => JSON.stringify(v))
                    : JSON.stringify(value[0]),
                );
              } else {
                const value = selectedResults.map(r => ({
                  id: (r as any).id,
                  name: r['name.full'],
                  npi: r.NPI,
                  npiData: r,
                }));
                updateValue(
                  step_component.field,
                  step_component.list ? value : value[0],
                );
              }
            }
          }}
        >
          {step_component.submit_label ||
            (step_component.list ? "That's everyone" : 'Continue')}
        </button>
        <button
          className="btn btn-subtle sm:order-first"
          onClick={() => forceSubmit()}
        >
          {step_component.skip_label ||
            (step_component.list ? "Didn't see anyone" : 'No one')}
        </button>
      </div>
    </div>
  );
};

ClinicalTableSearch.stepConfig = {};

export default ClinicalTableSearch;
