import classNames from 'classnames';
import React from 'react';

import { WeatherWidget } from '@assured/shared-types/ClaimWorkflow';

import { WidgetComponentSharedProps, WidgetFC } from '../types/widgetComponentTypes';
import WeatherForecast from './WeatherForecast';

const Weather: WidgetFC<WidgetComponentSharedProps<WeatherWidget>> = ({
  widget,
  className,
}) => {
  if (
    widget.value.demo === 'cat_claim' ||
    widget.value.demo === 'cat_claim_uri' ||
    widget.value.demo === 'cat_claim_wildfire'
  ) {
    return (
      <div
        className={classNames(
          'mx-6 my-6 rounded-md border-2 max-w-sm border-gray-300 overflow-hidden',
          className,
        )}
      >
        <img
          className="w-full"
          src={
            widget.value.demo === 'cat_claim'
              ? require('../../../images/etc/radar-hurricane_richard_20200915.png')
              : widget.value.demo === 'cat_claim_wildfire'
              ? require('../../../images/catastrophe/cat-fire.png')
              : require('../../../images/catastrophe/cat-1.png')
          }
        />
      </div>
    );
  }

  return (
    <WeatherForecast
      forecast={widget.value}
      className={classNames('text-cool-gray-600', className)}
    ></WeatherForecast>
  );
};
export default Weather;
