import React, { lazy, LazyExoticComponent } from 'react';
import PolicyWidget from '~/src/components/ClaimWorkflow/inline/Policy';
import { InlineFC } from '~/src/components/ClaimWorkflow/types/inlineComponentTypes';
import { WidgetFC } from '~/src/components/ClaimWorkflow/types/widgetComponentTypes';

import {
  InlineComponentType, StepComponentType, WidgetType
} from '@assured/shared-types/ClaimWorkflow';

import BodilyInjuryComponent from '../components/ClaimWorkflow/BodilyInjury';
import BuilderComponent from '../components/ClaimWorkflow/Builder';
import ChecklistComponent from '../components/ClaimWorkflow/Checklist';
import ClaimWorkflowNotification from '../components/ClaimWorkflow/ClaimWorkflowNotification';
import ClinicalTableSearchComponent from '../components/ClaimWorkflow/ClinicalTableSearch';
import CoverageSummaryComponent from '../components/ClaimWorkflow/CoverageSummary';
import DateTimeComponent from '../components/ClaimWorkflow/DateTime';
import DocumentComponent from '../components/ClaimWorkflow/Document';
import GenericComponent from '../components/ClaimWorkflow/Generic';
import GraphicComponent from '../components/ClaimWorkflow/inline/Graphic';
import PolicyInfoComponent from '../components/ClaimWorkflow/inline/Policy';
import ListComponent from '../components/ClaimWorkflow/List';
import LocationComponent from '../components/ClaimWorkflow/Location';
import LocationDirectionComponent from '../components/ClaimWorkflow/LocationDirection';
import MedicalAppointmentsComponent from '../components/ClaimWorkflow/MedicalAppointments';
import MultipartyFollowupSummaryComponent from '../components/ClaimWorkflow/MultipartyFollowupSummary';
import SelectComponent from '../components/ClaimWorkflow/Select';
import SelectMultiComponent from '../components/ClaimWorkflow/SelectMulti';
import SelectOrCreateComponent from '../components/ClaimWorkflow/SelectOrCreate';
import SignatureComponent from '../components/ClaimWorkflow/Signature';
import StringComponent from '../components/ClaimWorkflow/String';
import Title from '../components/ClaimWorkflow/Title';
import { StepComponentFC } from '../components/ClaimWorkflow/types/stepComponentTypes';
import UploadComponent from '../components/ClaimWorkflow/Upload';
import VehicleSeatmapComponent from '../components/ClaimWorkflow/VehicleSeatmap';
import ConfettiComponent from '../components/ClaimWorkflow/widgets/Confetti';
import MapComponent from '../components/ClaimWorkflow/widgets/Map';
import VehicleSeatmapWidgetComponent from '../components/ClaimWorkflow/widgets/Seatmap';
import WeatherComponent from '../components/ClaimWorkflow/widgets/Weather';

const VehicleDamagePickerComponent = lazy(
  () => import('../components/ClaimWorkflow/VehicleDamagePicker'),
);
const UserSatisfactionRating = React.lazy(
  () => import('../components/ClaimWorkflow/UserSatisfaction'),
);
const RepairCoordinationComponent = React.lazy(
  () => import('../components/ClaimWorkflow/RepairCoordination'),
);
const RentalCoordinationComponent = React.lazy(
  () => import('../components/ClaimWorkflow/RentalCoordination'),
);
const CollisionReconstructionComponent = React.lazy(
  () => import('../components/ClaimWorkflow/CollisionReconstruction'),
);

const STEP_COMPONENTS: Record<
  StepComponentType,
  StepComponentFC<any> | LazyExoticComponent<StepComponentFC<any>>
> = {
  select: SelectComponent,
  select_multi: SelectMultiComponent,
  select_or_create: SelectOrCreateComponent,
  string: StringComponent,
  location: LocationComponent,
  builder: BuilderComponent,
  upload: UploadComponent,
  datetime: DateTimeComponent,
  document: DocumentComponent,
  list: ListComponent,
  location_direction: LocationDirectionComponent,
  vehicle_seatmap: VehicleSeatmapComponent,
  vehicle_damage_picker: VehicleDamagePickerComponent,
  signature: SignatureComponent,
  coverage_summary: CoverageSummaryComponent,
  multiparty_followup_summary: MultipartyFollowupSummaryComponent,
  bodily_injury: BodilyInjuryComponent,
  checklist: ChecklistComponent,
  collision_reconstruction: CollisionReconstructionComponent,
  user_satisfaction: UserSatisfactionRating,
  repair_coordination: RepairCoordinationComponent,
  rental_coordination: RentalCoordinationComponent,
  clinical_table_search: ClinicalTableSearchComponent,
  medical_appointments: MedicalAppointmentsComponent,
  interstitial: () => <React.Fragment />,
};

const WIDGET_COMPONENTS: Record<WidgetType, WidgetFC<any>> = {
  weather: WeatherComponent,
  vehicle_seatmap: VehicleSeatmapWidgetComponent,
  confetti: ConfettiComponent,
  map: MapComponent,
};

const INLINE_COMPONENTS: Record<InlineComponentType, InlineFC<any>> = {
  policyInfo: PolicyInfoComponent,
  graphic: GraphicComponent,
};

export function useStepComponentLookup(type: StepComponentType) {
  return STEP_COMPONENTS[type];
}

export function useWidgetLookup(type: WidgetType) {
  return WIDGET_COMPONENTS[type];
}

export function useInlineComponentLookup(type: InlineComponentType) {
  return INLINE_COMPONENTS[type];
}
