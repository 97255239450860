import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CoverageSummaryStepComponent } from '@assured/shared-types/ClaimWorkflow';

import CoverageIcon from '../elements/CoverageIcon';
import { StepComponentFC, StepComponentSharedProps } from './types/stepComponentTypes';

interface CoverageSummaryProps
  extends StepComponentSharedProps<
    CoverageSummaryStepComponent,
    string | null
  > {
  forceSubmit: () => void;
}

const CoverageSummary: StepComponentFC<CoverageSummaryProps> = ({
  step_component,
  forceSubmit,
  className,
  workflowChannel,
}) => {
  const history = useHistory();
  const data = step_component.coverage_data;

  useEffect(() => {
    if (!data) {
      forceSubmit();
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className={classNames('relative', className)}>
      <div>
        <div
          className={classNames(
            'mx-auto items-center justify-center bg-green-100 rounded-md inline-flex px-4 py-2',
          )}
        >
          <svg
            className="h-6 w-6 text-green-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
          <span className="ml-2 font-medium">You're covered!</span>
        </div>
        {workflowChannel === 'sidekick' ? (
          <div className="mt-4 text-cool-gray-600 text-lg">
            Thank you! Based on the details you provided, here are coverages
            that may be relevant for this {data.incident_type} claim.
          </div>
        ) : (
          <div className="mt-4 text-black">
            Thank you! Based on the specific details you provided, here's how
            you,{' '}
            <span className="text-blue-500 font-semibold">
              {data.covered_name}
            </span>
            , are covered for this {data.incident_type}.
          </div>
        )}
        <div className="mb-6">
          {data.coverages.map(section => (
            <div>
              <h3 className="mt-6 mb-4 text-left font-semibold text-lg text-cool-gray-700">
                {section.header}
              </h3>
              <div className={classNames(`grid gap-4 grid-cols-1`)}>
                {section.items.map(item => (
                  <div className="flex items-start">
                    <div className="">
                      <CoverageIcon
                        className="text-blue-500 w-12 h-12 -mt-2 -ml-2"
                        icon={item.type}
                      />
                    </div>
                    <div className="text-left text-cool-gray-700 flex-1 ml-2 mr-3">
                      <div className="font-semibold text-sm leading-tight">
                        {item.label}
                      </div>
                      <div className="text-xs leading-tight mt-1">
                        {item.description}
                      </div>
                    </div>
                    <div className="text-cool-gray-700 font-medium text-xs leading-tight text-right mt-1">
                      {Object.keys(item.amounts).map(k => (
                        <div>
                          <div style={{ fontSize: 11 }}>
                            {k === 'per_person'
                              ? 'Per Person'
                              : k === 'per_occurrence'
                              ? 'Per Occurrence'
                              : k === 'deductible'
                              ? 'Deductible'
                              : k === 'radius'
                              ? 'Radius'
                              : k === 'per_day'
                              ? 'Per Day'
                              : null}
                          </div>
                          <div className="text-sm mb-2">
                            {item.amounts[k as keyof typeof item.amounts]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'ClaimWorkflowInner mt-4 flex flex-wrap justify-center',
        )}
      >
        <button
          className="btn btn-blue sm:order-last"
          onClick={() => forceSubmit()}
        >
          Got it
        </button>
        {workflowChannel !== 'sidekick' ? (
          <button
            className="btn btn-subtle sm:order-first"
            onClick={() => {
              history.push({
                pathname: '/policy/coverages',
                search: '?inline=true',
                state: { coverage_data: data },
              });
            }}
          >
            Coverage details
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CoverageSummary;
